import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './ApplicationForm.css'; // Import the CSS file
import SignatureCanvas from 'react-signature-canvas';

const ApplicationForm = () => {
  const signatureRef = useRef();
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  const [formData, setFormData] = useState({
    personalInfo: {
      fullName: '',
      dob: '',
      ssn: 'xxx-xx-',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      email: '',
      hearofus: '',
      realatives: ''
    },
    employmentInfo: {
      position: '',
      salary: '',
      startDate: '',
      nights: null, 
      weekends: null, 
      fullTime: null,
      relocate: null,
      availableDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      usCitizen: null,
      employeedBefore: null,
      employeedWhere: '',
      firedBefore: null,
      firedReason: '',
      convictedMisd: null,
      convictedFelon: null,
      convictedReason: ''

    },
    highSchool: {
      name: '',         // Name of the high school
      address: '',      // City/State
      years: '',        // Number of years attended
      graduate: null    // Whether graduated (true/false)
    },
    college: {
      name: '',         // Name of the college
      address: '',      // City/State
      from: '',         // Years attended
      graduate: null,   // Whether graduated (true/false)
      degree: ''        // Degree obtained
    },
    other: {
      name: '',         // Name of the other education institution
      address: '',      // City/State
      from: '',         // Years attended
      graduate: null,   // Whether graduated (true/false)
      degree: ''        // Degree obtained
    },
    previousEmployment: [
      {
        name: '',
        address: '',
        phone: '',
        citystate: '',
        supervisor: '',
        jobTitle: '',
        startingSalary: '',
        endingSalary: '',
        responsibilities: '',
        from: '',
        to: '',
        reasonForLeaving: '',
        contact: true,
        noContactReason: ''
      }
    ],
    references: [
      {
        name: '',
        relationship: '',
        company: '',
        phone: ''
      }
    ],
    otherInfo: [
      {
        relatedSkills: '',
        activities: ''
      }
    ],
    storeInfo: {
      storeNumber: '', // New field for store selection
    }
  });
  

  const handleChange = (section, key, value) => {
    console.log('Changing:', section, key, value); // Debug log

    setFormData(prevState => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [key]: value
      }
    }));
  };

  const handleStoreChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      storeInfo: {
        ...prevState.storeInfo,
        storeNumber: e.target.value,
      },
    }));
  };
  

  const handleEmploymentChange = (index, key, value) => {
    const newEmployment = [...formData.previousEmployment];
    newEmployment[index][key] = value;
    setFormData(prevState => ({
      ...prevState,
      previousEmployment: newEmployment
    }));
  };

  const handleDaysChange = (day, value) => {
    setFormData(prevState => ({
      ...prevState,
      employmentInfo: {
        ...prevState.employmentInfo,
        availableDays: {
          ...prevState.employmentInfo.availableDays,
          [day]: value
        }
      }
    }));
  };

  const handleReferenceChange = (index, key, value) => {
    const newReferences = [...formData.references];
    newReferences[index][key] = value;
    setFormData(prevState => ({
      ...prevState,
      references: newReferences
    }));
  };

  const handleotherInfoChange = (index, key, value) => {
    const newInfo = [...formData.otherInfo];
    newInfo[index][key] = value;
    setFormData(prevState => ({
      ...prevState,
      otherInfo: newInfo
    }));
  };


  const addEmployment = () => {
    setFormData(prevState => ({
      ...prevState,
      previousEmployment: [...prevState.previousEmployment, {
        name: '',
        address: '',
        phone: '',
        citystate: '',
        supervisor: '',
        jobTitle: '',
        startingSalary: '',
        endingSalary: '',
        responsibilities: '',
        from: '',
        to: '',
        reasonForLeaving: '',
        contact: false,
        noContactReason: ''
      }]
    }));
  };

  const addReference = () => {
    setFormData(prevState => ({
      ...prevState,
      references: [...prevState.references, {
        name: '',
        relationship: '',
        company: '',
        phone: ''
      }]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Mapping raw field names to user-friendly labels
    const fieldLabels = {
      fullName: "Full Name",
      dob: "Date of Birth",
      ssn: "Last 4 digits of SSN",
      address: "Address",
      city: "City",
      state: "State",
      zip: "ZIP Code",
      phone: "Phone Number",
      email: "Email Address",
      position: "Position Desired",
      salary: "Desired Salary",
      startDate: "Start Date",
      nights: "Can you work nights?",
      weekends: "Can you work weekends?",
      fullTime: "Full-Time or Part-Time Preference",
      relocate: "Willingness to Relocate",
      convictedMisd: "Have you been convicted of a misdemeanor in the last 10 years?",
      convictedFelon: "Have you ever been convicted of a felony?",
    };
  
    const requiredFields = [
      { section: "personalInfo", fields: ["fullName", "dob", "ssn", "address", "city", "state", "zip", "phone", "email"] },
      { section: "employmentInfo", fields: ["position", "salary", "startDate", "nights", "weekends", "fullTime", "relocate"] },
    ];
  
    let missingFields = [];
  
    requiredFields.forEach(({ section, fields }) => {
      fields.forEach((field) => {
        const value = formData[section][field];
        if (value === null || value === undefined || value === "") {
          missingFields.push(fieldLabels[field] || field); // Use label or default to field name
        }
      });
    });
  
    if (missingFields.length > 0) {
      alert(`The following fields are missing: \n${missingFields.join("\n")}`);
      return;
    }
  
    if (!formData.storeInfo.storeNumber) {
      alert("Please select a store.");
      return;
    }
  
    if (signatureRef.current.isEmpty()) {
      alert("Please provide your signature.");
      return;
    }
  
    try {
      const response = await fetch("https://shosky-app.onrender.com/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData), // Send the form data as JSON
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result.message); // Handle the server response
      navigate("/submitted"); // Redirect the user to the submitted page
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  
  
  
  
  
  

  const handleClearSignature = () => {
    signatureRef.current.clear();
  };


  return (
    <form onSubmit={handleSubmit} className="application-form">
      <h1>Application Form</h1>
      <hr />
  
{/* Store Selection Dropdown */}
<h2>Store Selection</h2>
      <div className="form-group">
        <label>Select the store you are applying for:</label>
        <select value={formData.storeInfo.storeNumber} onChange={handleStoreChange} style={{ textAlign: 'center' }}>
          <option value="">-- Select Store --</option>
          <option value="329857">329857 - Louisville, Kentucky</option>
          <option value="337229">337229 - Elizabethtown, Kentucky</option>
          <option value="332398">332398 - Glasgow, Kentucky</option>
          <option value="331061">331061 - Henderson, Kentucky</option>
          <option value="189235">189235 - Clarksville, Tennessee</option>
          <option value="360160">360160 - Clanton, Alabama</option>

        </select>
      </div>

      <hr />
           

      <h2>Personal Information</h2>
      <div className="form-group">
        <label>Full Name</label>
        <input
          type="text"
          value={formData.personalInfo.fullName}
          onChange={(e) => handleChange('personalInfo', 'fullName', e.target.value)}

          style={{ textAlign: 'center' }}
        />
      </div>




      
      <div className="form-row">
      <div className="form-group">
        <label>Date of birth</label>
        <input
            type="text"
            value={formData.personalInfo.dob}
            onChange={(e) => handleChange('personalInfo', 'dob', e.target.value)}
            style={{ textAlign: 'center' }}
  
          />
      </div>
      <div className="form-group">
  <label>Last 4 of SSN</label>
  <input
    type="text"
    value={`XXX-XX-${formData.personalInfo.SSN || ''}`}
    onChange={(e) => {
      const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
      const lastFourDigits = input.slice(-4); // Get the last 4 digits
      handleChange('personalInfo', 'SSN', `${lastFourDigits}`); // Update state with only the last 4 digits
    }}
    maxLength={11} // 6 characters for 'XXX-XX-' + 4 digits = 11
    style={{ textAlign: 'center' }}
    required i wa
  />
</div>

      </div>
      <div className="form-row">
        <div className="form-group">
          <label>Address</label>
          <input
            type="text"
            value={formData.personalInfo.address}
            onChange={(e) => handleChange('personalInfo', 'address', e.target.value)}
            style={{ textAlign: 'center' }}
  
          />
        </div>
      </div>
      <div className="form-row-three">
        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            value={formData.personalInfo.city}
            onChange={(e) => handleChange('personalInfo', 'city', e.target.value)}
            style={{ textAlign: 'center' }}
  
          />
        </div>
        <div className="form-group">
          <label>State</label>
          <input
            type="text"
            value={formData.personalInfo.state}
            onChange={(e) => handleChange('personalInfo', 'state', e.target.value)}
            style={{ textAlign: 'center' }}
  
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label>ZIP</label>
          <input
            type="text"
            value={formData.personalInfo.zip}
            onChange={(e) => handleChange('personalInfo', 'zip', e.target.value)}
            style={{ textAlign: 'center' }}
  
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="text"
            value={formData.personalInfo.phone}
            onChange={(e) => handleChange('personalInfo', 'phone', e.target.value)}
            style={{ textAlign: 'center' }}
  
          />
        </div>
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          value={formData.personalInfo.email}
          onChange={(e) => handleChange('personalInfo', 'email', e.target.value)}
          style={{ textAlign: 'center' }}

        />
      </div>
      <div className="form-group">
        <label>How did you hear of us?</label>
        <input
          type="text"
          value={formData.personalInfo.hearofus}
          onChange={(e) => handleChange('personalInfo', 'hearofus', e.target.value)}
          style={{ textAlign: 'center' }}
        />
      </div>
      <div className="form-group">
        <label>Do you have any relatives who are employeed by Shoney's? If so, please state name(s) and relationship(s)</label>
        <input
          type="text"
          value={formData.personalInfo.realatives}
          onChange={(e) => handleChange('personalInfo', 'realatives', e.target.value)}
          style={{ textAlign: 'center' }}
        />
      </div>
      
      <hr />
              <hr />
              <hr />


      <h2>Employment Information</h2>
      <div className="form-group">
        <label>Position Desired</label>
        <select
          value={formData.employmentInfo.position}
          onChange={(e) => handleChange('employmentInfo', 'position', e.target.value)}
          style={{ textAlign: 'center' }}
        >
          <option value="">-- Select Position --</option>
          <option value="server">Server</option>
          <option value="cashier">Cashier</option>
          <option value="prep">Prep</option>
          <option value="cook">Cook</option>
          <option value="buffet">Buffet</option>
          <option value="dishwasher">Dishwasher</option>
          <option value="manager">Manager</option>
          <option value="any">Any</option>
        </select>
      </div>

      <div className="form-group">
        <label>Desired Salary</label>
        <input
          type="text"
          value={formData.employmentInfo.salary}
          onChange={(e) => handleChange('employmentInfo', 'salary', e.target.value)}
          style={{ textAlign: 'center' }}

        />
      </div>
      <div className="form-group">
        <label>Date Available to Start</label>
        <input
            type="text"
            value={formData.employmentInfo.startDate}
            onChange={(e) => handleChange('employmentInfo', 'startDate', e.target.value)}
            style={{ textAlign: 'center' }}
  
          />
      </div>
      <br></br>
      <div className="form-group">
       <label>Can you work nights?</label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.employmentInfo.nights === true}
        onChange={() => handleChange('employmentInfo', 'nights', true)}
        required
      />
      Yes
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.employmentInfo.nights === false}
        onChange={() => handleChange('employmentInfo', 'nights', false)}
        required
      />
      No
    </label>
  </div>
  <br></br>
  <label>Can you work weekends?</label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.employmentInfo.weekends === true}
        onChange={() => handleChange('employmentInfo', 'weekends', true)}
        required
      />
      Yes
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.employmentInfo.weekends === false}
        onChange={() => handleChange('employmentInfo', 'weekends', false)}
        required
      />
      No
    </label>
  </div>
  <label>
  <br></br>
  </label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.employmentInfo.fullTime === true}
        onChange={() => handleChange('employmentInfo', 'fullTime', true)}
      />
      Full Time
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.employmentInfo.fullTime === false}
        onChange={() => handleChange('employmentInfo', 'fullTime', false)}
      />
      Part Time
    </label>
  </div>
  <br></br>
  <label>Are you willing to relocate?</label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.employmentInfo.relocate === true}
        onChange={() => handleChange('employmentInfo', 'relocate', true)}
      />
      Yes
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.employmentInfo.relocate === false}
        onChange={() => handleChange('employmentInfo', 'relocate', false)}
      />
      No
    </label>
  </div>
      </div>
      <br></br>
      <div className="form-group">
  <label>Days Available to Work</label>
  <div>
    {Object.keys(formData.employmentInfo.availableDays).map((day) => (
      <label key={day} style={{ marginRight: '10px' }}>
        <input
          type="checkbox"
          name={day}
          checked={formData.employmentInfo.availableDays[day]}
          onChange={(e) => handleDaysChange(day, e.target.checked)}
        />
        {day.charAt(0).toUpperCase() + day.slice(1)}
      </label>
    ))}
  </div>
</div>
<br></br>
        <div className="form-group">

        <label>Do you have the legal right to work in the United States?</label>
<div>
  <label>
    <input
      type="radio"
      name="usCitizen"
      value="yes"
      checked={formData.employmentInfo.usCitizen === true}
      onChange={() => handleChange('employmentInfo', 'usCitizen', true)}
    />
    Yes
  </label>
  <label style={{ marginLeft: '10px' }}>
    <input
      type="radio"
      name="usCitizen"
      value="no"
      checked={formData.employmentInfo.usCitizen === false}
      onChange={() => handleChange('employmentInfo', 'usCitizen', false)}
    />
    No
  </label>
</div>

  <br></br>
  <label>Have you ever been employed with any Shoney's restaurant before?</label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.employmentInfo.employeedBefore === true}
        onChange={() => handleChange('employmentInfo', 'employeedBefore', true)}
        required
      />
      Yes
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.employmentInfo.employeedBefore === false}
        onChange={() => handleChange('employmentInfo', 'employeedBefore', false)}
        required
      />
      No
    </label>
    {(formData.employmentInfo.employeedBefore) && (
  <div className="form-group">
  <label>If "YES", dates and location(s)</label>
  <input
    type="text"
    value={formData.personalInfo.employeedWhere}
    onChange={(e) => handleChange('employmentInfo', 'employeedWhere', e.target.value)}
  />
</div>
)}
    
  </div>
  <br></br>
  <label>Have you ever been fired from any employer?</label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.employmentInfo.firedBefore === true}
        onChange={() => handleChange('employmentInfo', 'firedBefore', true)}
        required
      />
      Yes
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.employmentInfo.firedBefore === false}
        onChange={() => handleChange('employmentInfo', 'firedBefore', false)}
        required
      />
      No
    </label>
    </div>
    {(formData.employmentInfo.firedBefore) && (
  <div className="form-group">
  <label>If "YES", dates and location(s)</label>
  <input
    type="text"
    value={formData.personalInfo.firedReason}
    onChange={(e) => handleChange('employmentInfo', 'firedReason', e.target.value)}
  />
</div>
)}
    
<br></br>
<br></br>
  <label>Have you ever been convicted of a misdermeanor or plead "no contest" in the last ten years?</label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.employmentInfo.convictedMisd === true}
        onChange={() => handleChange('employmentInfo', 'convictedMisd', true)}
        required
      />
      Yes
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.employmentInfo.convictedMisd === false}
        onChange={() => handleChange('employmentInfo', 'convictedMisd', false)}
        required
      />
      No
    </label>
  </div>
  <label>Have you ever been convicted of a felony?</label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.employmentInfo.convictedFelon === true}
        onChange={() => handleChange('employmentInfo', 'convictedFelon', true)}
        required
      />
      Yes
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.employmentInfo.convictedFelon === false}
        onChange={() => handleChange('employmentInfo', 'convictedFelon', false)}
        required
      />
      No
    </label>
     {(formData.employmentInfo.convictedMisd || formData.employmentInfo.convictedFelon) && (
  <div className="form-group">
    <label>If "YES", to either question, explain</label>
    <input
      type="text"
      value={formData.employmentInfo.convictedReason}
      onChange={(e) => handleChange('employmentInfo', 'convictedReason', e.target.value)}
    />
  </div>
)}

  </div>
  
  <br></br><br></br>
</div>
<hr />
              <hr />
              <hr />

<h2>Education</h2>
<h3>High School</h3>
<div className="form-row">
  <div className="form-group">
    <label>Name</label>
    <input
      type="text"
      value={formData.highSchool.name}
      onChange={(e) => handleChange('highSchool', 'name', e.target.value)}
      style={{ textAlign: 'center' }}
    />
  </div>
  <div className="form-group">
    <label>City/State</label>
    <input
      type="text"
      value={formData.highSchool.address}
      onChange={(e) => handleChange('highSchool', 'address', e.target.value)}
      style={{ textAlign: 'center' }}
    />
  </div>
</div>
<div className="form-row">
  <div className="form-group">
    <label>Number of years attended</label>
    <input
      type="text"
      value={formData.highSchool.years}
      onChange={(e) => handleChange('highSchool', 'years', e.target.value)}
      style={{ textAlign: 'center' }}
    />
  </div>
</div>
<div className="form-group">
  <label>Did you graduate?</label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.highSchool.graduate === true}
        onChange={() => handleChange('highSchool', 'graduate', true)}
      />
      Yes
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.highSchool.graduate === false}
        onChange={() => handleChange('highSchool', 'graduate', false)}
      />
      No
    </label>
  </div>
</div>
<hr />

<h3>College</h3>
<div className="form-row">
  <div className="form-group">
    <label>Name</label>
    <input
      type="text"
      value={formData.college.name}
      onChange={(e) => handleChange('college', 'name', e.target.value)}
      style={{ textAlign: 'center' }}
    />
  </div>
  <div className="form-group">
    <label>City/State</label>
    <input
      type="text"
      value={formData.college.address}
      onChange={(e) => handleChange('college', 'address', e.target.value)}
      style={{ textAlign: 'center' }}
    />
  </div>
</div>
<div className="form-row">
  <div className="form-group">
    <label>Number of years attended</label>
    <input
      type="text"
      value={formData.college.from}
      onChange={(e) => handleChange('college', 'from', e.target.value)}
      style={{ textAlign: 'center' }}
    />
  </div>
</div>
<div className="form-group">
  <label>Did you graduate?</label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.college.graduate === true}
        onChange={() => handleChange('college', 'graduate', true)}
      />
      Yes
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.college.graduate === false}
        onChange={() => handleChange('college', 'graduate', false)}
      />
      No
    </label>
  </div>
</div>
<div className="form-group">
  <label>Degree</label>
  <input
    type="text"
    value={formData.college.degree}
    onChange={(e) => handleChange('college', 'degree', e.target.value)}
    style={{ textAlign: 'center' }}
  />
</div>
<hr />

<h3>Other</h3>
<div className="form-row">
  <div className="form-group">
    <label>Name</label>
    <input
      type="text"
      value={formData.other.name}
      onChange={(e) => handleChange('other', 'name', e.target.value)}
      style={{ textAlign: 'center' }}
    />
  </div>
  <div className="form-group">
    <label>City/State</label>
    <input
      type="text"
      value={formData.other.address}
      onChange={(e) => handleChange('other', 'address', e.target.value)}
      style={{ textAlign: 'center' }}
    />
  </div>
</div>
<div className="form-row">
  <div className="form-group">
    <label>Number of years attended</label>
    <input
      type="text"
      value={formData.other.from}
      onChange={(e) => handleChange('other', 'from', e.target.value)}
      style={{ textAlign: 'center' }}
    />
  </div>
</div>
<div className="form-group">
  <label>Did you graduate?</label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.other.graduate === true}
        onChange={() => handleChange('other', 'graduate', true)}
      />
      Yes
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.other.graduate === false}
        onChange={() => handleChange('other', 'graduate', false)}
      />
      No
    </label>
  </div>
</div>
<div className="form-group">
  <label>Degree</label>
  <input
    type="text"
    value={formData.other.degree}
    onChange={(e) => handleChange('other', 'degree', e.target.value)}
    style={{ textAlign: 'center' }}
  />
</div>

<hr />
              <hr />
              <hr />
      <h2>Previous Employment</h2>
      {formData.previousEmployment.map((employment, index) => (
        <div key={index}>
          <div className="form-row">
            <div className="form-group">
              <label>Employer Name</label>
              <input
                type="text"
                value={employment.name}
                onChange={(e) => handleEmploymentChange(index, 'name', e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </div>
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                value={employment.address}
                onChange={(e) => handleEmploymentChange(index, 'address', e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Phone</label>
              <input
                type="text"
                value={employment.phone}
                onChange={(e) => handleEmploymentChange(index, 'phone', e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </div>
            <div className="form-group">
              <label>City/State</label>
              <input
                type="text"
                value={employment.citystate}
                onChange={(e) => handleEmploymentChange(index, 'citystate', e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Supervisor</label>
              <input
                type="text"
                value={employment.supervisor}
                onChange={(e) => handleEmploymentChange(index, 'supervisor', e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </div>
            <div className="form-group">
              <label>Job Title</label>
              <input
                type="text"
                value={employment.jobTitle}
                onChange={(e) => handleEmploymentChange(index, 'jobTitle', e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Starting Salary</label>
              <input
                type="text"
                value={employment.startingSalary}
                onChange={(e) => handleEmploymentChange(index, 'startingSalary', e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </div>
            <div className="form-group">
              <label>Ending Salary</label>
              <input
                type="text"
                value={employment.endingSalary}
                onChange={(e) => handleEmploymentChange(index, 'endingSalary', e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </div>
          </div>
          <div className="form-group">
              <label>Responsibilities</label>
              <input
                type="text"
                value={employment.responsibilities}
                onChange={(e) => handleEmploymentChange(index, 'responsibilities', e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </div>
          <div className="form-row">
            <div className="form-group">
              <label>Start Date</label>
              <input
                type="text"
                value={employment.from}
                onChange={(e) => handleEmploymentChange(index, 'from', e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </div>
            <div className="form-group">
              <label>End Date</label>
              <input
                type="text"
                value={employment.to}
                onChange={(e) => handleEmploymentChange(index, 'to', e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </div>
          </div>
          <div className="form-group">
            <label>Reason for Leaving</label>
            <input
              type="text"
              value={employment.reasonForLeaving}
              onChange={(e) => handleEmploymentChange(index, 'reasonForLeaving', e.target.value)}
            />
          <label>Can we contact this employer?</label>
  <div>
    <label>
      <input
        type="radio"
        value="yes"
        checked={formData.employmentInfo.contact === true}
        onChange={() => handleChange('employmentInfo', 'contact', true)}
      />
      Yes
    </label>
    <label style={{ marginLeft: '10px' }}>
      <input
        type="radio"
        value="no"
        checked={formData.employmentInfo.contact === false}
        onChange={() => handleChange('employmentInfo', 'contact', false)}
      />
      No
    </label>
    {(formData.employmentInfo.contact == false) && (
   <div className="form-group">
   <label>If no, provide details/alternative</label>
   <input
     type="text"
     value={employment.noContactReason}
     onChange={(e) => handleEmploymentChange(index, 'noContactReason', e.target.value)}
   />
 </div>
)}
   
    </div>         
     </div>
        </div>
      ))}
      
      <br></br>
      <button type="button" onClick={addEmployment}>Add Employment</button>
      <br></br><br></br><br></br>
      <hr />
              <hr />
              <hr />
              <br></br>
      <h2>References</h2>
      {formData.references.map((reference, index) => (
        <div key={index} className="form-row">
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              value={reference.name}
              onChange={(e) => handleReferenceChange(index, 'name', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Relationship</label>
            <input
              type="text"
              value={reference.relationship}
              onChange={(e) => handleReferenceChange(index, 'relationship', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              value={reference.phone}
              onChange={(e) => handleReferenceChange(index, 'phone', e.target.value)}
            />
          </div>
        </div>
      ))}
      <button type="button" onClick={addReference}>Add Reference</button>
      <br></br><br></br><br></br>
      <hr />
              <hr />
              <hr />
              <br></br>

      <h2>Related Skills</h2>
{formData.otherInfo.map((otherInfo, index) => (
  <div key={index} className="form-row">
    <div className="form-group">
      <label>Describe any specialized training, apprenticeships, or skills applicable to the job you are seeking.</label>
      <textarea
        value={otherInfo.relatedSkills}
        onChange={(e) => handleotherInfoChange(index, 'relatedSkills', e.target.value)}
        style={{ width: '100%', height: '100px', resize: 'both' }}
      />
    </div>
  </div>
))}
    <br></br><br></br><br></br>
      <hr />
              <hr />
              <hr />
              <br></br>

<h2>Additional Information</h2>
{formData.otherInfo.map((otherInfo, index) => (
  <div key={index} className="form-row">
    <div className="form-group">
      <label>
        LIST PROFESSIONAL, TRADE, BUSINESS OR COMMUNITY ACTIVITIES AND OFFICES HELD.
        You may exclude membership which could reveal age, race, color, sex, 
        pregnancy, religion, national origin, citizenship, veteran
        status, disability, or any other legally protected status
      </label>
      <textarea
        value={otherInfo.activities}
        onChange={(e) => handleotherInfoChange(index, 'activities', e.target.value)}
        style={{ width: '100%', height: '100px', resize: 'both' }} 
      />
    </div>
  </div>
))}


<br></br><br></br>
      <hr />
              <hr />
              <hr />
              <br></br>

<h2>CERTIFICATION AND AUTHORIZATION  </h2>
      {formData.references.map((reference, index) => (
        <div key={index} className="form-row">
          <div className="form-group">
            <label>I certify that the information I have provided in this application is true and complete to the best of my knowledge. I give
Bullitt Venture's Inc and any person or company acting on its behalf, the right to investigate my background. I hereby
authorize and request any present or former employer, school, credit agency, financial institution, law enforcement
agency, court, military service, or other persons having knowledge or information about me to furnish the bearer of this
authorization with any and all information in their possession regarding with the same authority as the original, and
specifically waive any written notice from any present or former employer who may provide information based upon this
request. I release from liability any person or entity which provides information pursuant to this authorization and
request.
<br></br>
<br></br>

I understand that noting in this employment application, the granting of an interview or my subsequent employment
with Bullitt Venture's, Inc. is intended to create an employment contract between myself and Bullitt Venture's Inc. I
understand and agree that, if hired, my employment will be terminable at will and may be terminated by me or Bullitt
Venture's Inc. at any time and for any reason. I understand that no person has any authority to enter into any agreement
that would cancel or modify this"at will" provision.
<br></br>
<br></br>

If employed, I understand I will be required to provide original documents, which verify my identity and right to work in
the United States, under the Immigration Reform and Control Act (IRCA) of 1986. The document(s) provided will be used
for completion of Form 1-9.
<br></br>
<br></br>

I understand that omitting information or providing false or misleading information in my application or interview(s)
may result in termination of the employment process or discharge if such false or misleading information is discovered
after I have been hired.
<br></br>
<br></br>

As allowed by law, I also agree to submit to random drug tests and other investigative interviews, methods or tests
conducted by or at the direction of Bullitt Venture's Inc. and I understand that the results of such tests may be used as
evidence in legal or administrative proceedings and may also be used in considering my status for continued
employment and as a basis for rejecting my application or terminating my employment. I understand if at any point
during my employment with Bullitt Venture's Inc.any adverse action is taken based in whole or in part on any
information contained in a consumer report, Bullitt Venture's will, to the extent required by applicable law, provide me
with written notice of the adverse action that contains the name, address, and telephone number of the consumer
reporting agency. I will also get a notice of my right to obtain a free copy of the report, and of my right to dispute the
accuracy or completeness of the information with the agency providing the report. I understand that the consumer
reporting agency supplying the report does not make any decisions and is unable to provide specific reasons why the
adverse action was taken.
<br></br>
<br></br>

I also agree to arbitrate any matters of legal consequence if any such matters arise in exchange for Bullitt Venture's Inc's
consideration of the application. I understand that this agreement is a condition of any employment and of any
continued or future employment with Bullitt Venture's Inc.
<br></br>
<br></br>

I understand that the information contained herein is to be used in a confidential manner. Upon acceptance of
employment with Bullitt Venture's Inc. I agree to follow all its policies, procedures, rulesand regulations.
<br></br></label>
           
          </div>
        </div>

        
      ))}
  
  <br></br>
<br></br>
  <h2>Please sign Below  </h2>

<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                <div style={{ border: '2px solid #ccc', width: '75vw' }}>
                  <SignatureCanvas ref={signatureRef} canvasProps={{ style: { height: '250px', width: '100%' } }} />
                </div>
              </div>
              <br />
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', marginTop: '-25px' }}>
                <button onClick={handleClearSignature}>Clear Signature</button>
              </div>

      <div className="button-group">
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default ApplicationForm;
