import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // For programmatic navigation
import axios from 'axios'; // For API calls

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate(); // Hook to navigate programmatically


  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      axios
        .get('https://shosky-app.onrender.com/api/checkPerms', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          // Token is valid, redirect to /mang
          navigate('/mang');
        })
        .catch((error) => {
          // If the token is invalid, remove it silently
          if (error.response && error.response.status === 403) {
            localStorage.removeItem('authToken');
          }
        });
    }
  }, [navigate]);
  

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://shosky-app.onrender.com/login', {
        email,
        password,
      });

      setError('');
      setSuccess('Login successful!');

      // Store the JWT token in localStorage (or sessionStorage)
      localStorage.setItem('authToken', response.data.token);

      // Redirect or navigate to the protected page after login
      window.location.href = '/mang';
    } catch (error) {
      setSuccess('');
      if (error.response && error.response.data) {
        setError(error.response.data.message);
      } else {
        setError('An error occurred during login');
      }
    }
  };

  return (
    <Container fluid className="login-container">
      <Row className="justify-content-center align-items-center vh-100">
        <Col md={6} lg={4}>
          <Card className="shadow-sm">
            <Card.Body>
              <h3 className="text-center mb-4">Login</h3>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Form onSubmit={handleLogin}>
                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Log In
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
