import React from 'react';
import './SubmittedPage.css'; // CSS file for styling

const SubmittedPage = () => {
  return (
    <div className="submitted-container">
      <div className="submitted-box">
        <div className="icon-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="96"
            fill="currentColor"
            className="check-icon"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM7.667 11.727l5.333-5.333-1.06-1.06-4.273 4.272-2.273-2.273-1.06 1.06 3.333 3.334z" />
          </svg>
        </div>
        <h1>Application Submitted!</h1>
        <p>Thank you for submitting your application. We’ll get back to you as soon as possible.</p>
       
      </div>
    </div>
  );
};

export default SubmittedPage;
