import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Users.css';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserLocation, setNewUserLocation] = useState('');
  const [newUserSuccess, setNewUserSuccess] = useState(null);
  const [passwordModal, setPasswordModal] = useState(false);
  const [passwordUserId, setPasswordUserId] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [locationModal, setLocationModal] = useState(false);
  const [locationUserId, setLocationUserId] = useState(null);
  const [newLocation, setNewLocation] = useState('');
  const [showNewUserForm, setShowNewUserForm] = useState(false);
  const [error, setError] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const ip = 'https://shosky-app.onrender.com';
  const navigate = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      axios
        .get('https://shosky-app.onrender.com/api/permissions', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsAdmin(response.data);
        })
        .catch((error) => {
          // If the token is invalid, remove it silently
          if (error.response && error.response.status === 403) {
            localStorage.removeItem('authToken');
          } else {
            // Log other errors for debugging purposes
            console.error(error.response?.data || error.message);
          }
        });
    }
  }, []);

  
  useEffect(() => {
    checkAdmin();
    fetchUsers();
  }, []);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(''), 60000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const checkAdmin = async () => {
    // try {
    //   const token = localStorage.getItem('authToken');
    //   const response = await axios.get(`${ip}/check-admin`, {
    //     headers: { Authorization: `Bearer ${token}` },
    //   });
    //   setIsAdmin(response.data.isAdmin);
    // } catch (error) {
    //   console.error('Error checking admin status:', error);
    // }
  };

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${ip}/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      if (error.response && error.response.status === 403) {
        navigate('/lgn');
      }
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${ip}/users/create`, {
        email: newUserEmail,
        password: newUserPassword,
        location: newUserLocation,
      });
      setNewUserSuccess(response.data);
      setNewUserEmail('');
      setNewUserPassword('');
      setNewUserLocation('');
      setShowNewUserForm(false);
      fetchUsers();
    } catch (error) {
      setError('Error creating user. Email may already exist.');
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!isAdmin) return;

    const confirmed = window.confirm('Are you sure you want to delete this user?');
    if (!confirmed) return;

    try {
      const response = await axios.delete(`${ip}/users/delete/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
      });
      alert(response.data.message);
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
      if (error.response) {
        alert(error.response.data.message || 'An error occurred while deleting the user.');
      }
    }
  };

  const handleOpenPasswordModal = (userId) => {
    setPasswordUserId(userId);
    setPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setPasswordModal(false);
    setPasswordUserId(null);
    setNewPassword('');
  };

  const handleUpdatePassword = async () => {
    try {
      await axios.post(`${ip}/users/update-password`, {
        userId: passwordUserId,
        newPassword,
      });
      setNewPassword('');
      handleClosePasswordModal();
      fetchUsers();
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };

  const handleOpenLocationModal = (userId, currentLocation) => {
    setLocationUserId(userId);
    setNewLocation(currentLocation);
    setLocationModal(true);
  };

  const handleCloseLocationModal = () => {
    setLocationModal(false);
    setLocationUserId(null);
    setNewLocation('');
  };

  const handleUpdateLocation = async () => {
    try {
      const token = localStorage.getItem('authToken');
      await axios.post(
        `${ip}/users/update-location`,
        {
          userId: locationUserId,
          location: newLocation,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert('Location updated successfully!');
      handleCloseLocationModal();
      fetchUsers();
    } catch (error) {
      console.error('Error updating location:', error);
      if (error.response) {
        alert(error.response.data.message || 'An error occurred while updating the location.');
      }
    }
  };
  

  return (
    <div className="container user-management-container mt-5">
      <h1 className="mb-4">Manage Users</h1>

      {!showNewUserForm && (
        <div className="d-flex justify-content-end mb-4">
          <Button variant="success" onClick={() => setShowNewUserForm(true)}>
            New User
          </Button>
        </div>
      )}

      {showNewUserForm && (
        <>
          <h3>Create New User</h3>
          {newUserSuccess && (
            <Alert variant="success">
              <strong>User created!</strong> Email: {newUserSuccess.email}, Password: {newUserSuccess.password}
            </Alert>
          )}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleCreateUser} className="mb-4 user-form p-4 border rounded shadow-sm">
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                placeholder="Enter email"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={newUserPassword}
                onChange={(e) => setNewUserPassword(e.target.value)}
                placeholder="Enter password"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <Form.Select
                value={newUserLocation}
                onChange={(e) => setNewUserLocation(e.target.value)}
                required
              >
                <option value="">Select location...</option>
                <option value="329857">329857 - Louisville, Kentucky</option>
                <option value="337229">337229 - Elizabethtown, Kentucky</option>
                <option value="332398">332398 - Glasgow, Kentucky</option>
                <option value="331061">331061 - Henderson, Kentucky</option>
                <option value="189235">189235 - Clarksville, Tennessee</option>
                <option value="360160">360160 - Clanton, Alabama</option>
                <option value="Admin">Admin</option>
              </Form.Select>
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit" className="me-2">
                Create User
              </Button>
              <Button variant="danger" onClick={() => setShowNewUserForm(false)}>
                Cancel
              </Button>
            </div>
          </Form>
        </>
      )}

      <h3>All Users</h3>
      <div className="table-responsive">
        <table className="table table-hover table-striped modern-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Location</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.email}</td>
                <td>{user.location}</td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => handleOpenPasswordModal(user.id)}
                    className="action-btn me-2"
                  >
                    Change Password
                  </Button>
                  <Button
                    variant="info"
                    onClick={() => handleOpenLocationModal(user.id, user.location)}
                    className="action-btn2 me-2"
                  >
                    Change Location
                  </Button>
                  {isAdmin && (
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteUser(user.id)}
                      className="action-btn3"
                    >
                      Delete
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={passwordModal} onHide={handleClosePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePasswordModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdatePassword}>
            Update Password
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={locationModal} onHide={handleCloseLocationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>New Location</Form.Label>
              <Form.Select
                value={newLocation}
                onChange={(e) => setNewLocation(e.target.value)}
                required
              >
                <option value="">Select location...</option>
                <option value="329857">329857 - Louisville, Kentucky</option>
                <option value="337229">337229 - Elizabethtown, Kentucky</option>
                <option value="332398">332398 - Glasgow, Kentucky</option>
                <option value="331061">331061 - Henderson, Kentucky</option>
                <option value="189235">189235 - Clarksville, Tennessee</option>
                <option value="360160">360160 - Clanton, Alabama</option>
                <option value="Admin">Admin</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLocationModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateLocation}>
            Update Location
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Users;
