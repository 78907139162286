import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Mang = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('status_pending');
  const [location, setLocation] = useState('');
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      axios
        .get('https://shosky-app.onrender.com/api/permissions', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsAdmin(response.data);
        })
        .catch((error) => {
          // If the token is invalid, remove it silently
          if (error.response && error.response.status === 403) {
            localStorage.removeItem('authToken');
          }
        });
    }
  }, []);
  

  useEffect(() => {
    fetchData();
  }, [searchTerm, sortField, location]);

  const fetchData = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/lgn');
      return;
    }

    try {
      const response = await axios.get('https://shosky-app.onrender.com/query-data', {
        headers: { Authorization: `Bearer ${token}` },
        params: { search: searchTerm, sort: sortField, location },
      });
      setData(response.data);
    } catch (error) {
      if (error.response?.status === 403) navigate('/lgn');
    }
  };

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleLocation = (e) => setLocation(e.target.value);
  const handleSortChange = (e) => setSortField(e.target.value);
  const handleClose = () => setShow(false);
  const handleUpdateStatus = async (newStatus, applicantId) => {
    const token = localStorage.getItem('authToken'); // Get token from local storage

    if (!token) {
      console.error('No token found, redirecting to login...');
      navigate('/lgn'); // Redirect to login page if no token
      return;
    }

    try {
      const response = await axios.post(
        'https://shosky-app.onrender.com/update-status',
        {
          personal_info_id: applicantId,
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send the token with the request
          },
        }
      );
      console.log(response.data.message); // Optionally log the response
      setShow(false); // Close the modal after approving/denying
      fetchData(); // Refresh the table to show the updated status
    } catch (error) {
      console.error('Error updating status', error);
    }
  };


  const handleShowDetails = async (id) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/lgn');
      return;
    }

    try {
      const response = await axios.get(`https://shosky-app.onrender.com/applicant/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSelectedApplicant(response.data);
      setShow(true);
    } catch (error) {
      if (error.response?.status === 403) navigate('/lgn');
    }
  };

  const handlePrint = () => {
    const printContent = document.getElementById('printable-content');
    if (!printContent) {
      console.error('Printable content not found');
      return;
    }
  
    // Save the current content of the page
    const originalContent = document.body.innerHTML;
  
    // Create styled content for printing
    const styledContent = `
      <style>
        @media print {
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            line-height: 1.6;
          }
          .print-header {
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .section {
            margin-bottom: 20px;
            border: 1px solid #ccc;
            padding: 10px;
            border-radius: 8px;
          }
          .section-title {
            font-size: 20px;
            font-weight: bold;
            text-decoration: underline;
            margin-bottom: 10px;
          }
          .section p {
            margin: 5px 0;
          }
          hr {
            margin: 20px 0;
            border: 1px solid #ccc;
          }
        }
      </style>
      <div class="print-header">Applicant Details</div>
      ${printContent.innerHTML}
    `;
  
    // Replace the body content with styled printable content
    document.body.innerHTML = styledContent;
  
    // Trigger the print dialog
    window.print();
  
    // Restore the original content after printing
    document.body.innerHTML = originalContent;
    window.location.reload(); // Optional: Reload the page to ensure it restores fully
  };
  
  
  

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Manage Applications</h1>
      <div className="form-group row">
        {isAdmin && (
          <>
            <label className="col-sm-2 col-form-label">Location</label>
            <div className="col-sm-10">
              <select className="form-control" value={location} onChange={handleLocation}>
                <option value="">All locations</option>
                <option value="329857">329857 - Louisville, Kentucky</option>
                <option value="337229">337229 - Elizabethtown, Kentucky</option>
                <option value="332398">332398 - Glasgow, Kentucky</option>
                <option value="331061">331061 - Henderson, Kentucky</option>
                <option value="189235">189235 - Clarksville, Tennessee</option>
                <option value="360160">360160 - Clanton, Alabama</option>
              </select>
            </div>
          </>
        )}
      </div>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Search</label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            placeholder="Search applicants..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Sort By</label>
        <div className="col-sm-10">
          <select className="form-control" value={sortField} onChange={handleSortChange}>
            <option value="id_desc">Most Recent</option>
            <option value="id_asc">Oldest First</option>
            <option value="full_name_asc">Name (A-Z)</option>
            <option value="full_name_desc">Name (Z-A)</option>
            <option value="status_approved">Approved Only</option>
            <option value="status_denied">Denied Only</option>
            <option value="status_pending">Pending Only</option>
            <option value="status_hired">Hired Only</option>
            {isAdmin && (
            <option value="status_archived">Archived Only</option>
            )}
          </select>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead className="thead-dark">
            <tr>
              <th>Store Number</th>
              <th>Name</th>
              <th>Date of Birth</th>
              <th>Last 4 SSN</th>
              <th>Position Desired</th>
              <th>App Status</th>
              <th>Submitted Time</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item) => (
                <tr key={item.id}>
                  <td>{item.storeNumber}</td>
                  <td>
                    <Button variant="link" onClick={() => handleShowDetails(item.id)}>
                      {item.full_name}
                    </Button>
                  </td>
                  <td>{item.dob}</td>
                  <td>{`XXX-XX-${item.ssn.slice(-4)}`}</td>
                  <td>{item.position}</td>
                  <td>{item.application_status || 'N/A'}</td>
                  <td>{item.time_submitted || 'N/A'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
  <div style={{ width: '100%', textAlign: 'center' }}>
    <Modal.Title>Applicant Details</Modal.Title>
    <Button
  style={{
    backgroundColor: '#32CD32', // Brighter green
    borderColor: '#32CD32', // Same as background for a flat look
  }}
  onMouseEnter={(e) => (e.target.style.backgroundColor = '#228B22')} // Darker green
  onMouseLeave={(e) => (e.target.style.backgroundColor = '#32CD32')} // Back to brighter
  onClick={handlePrint}
>
  Print
</Button>

  </div>
</Modal.Header>

        <Modal.Body>
        <div id="printable-content">

    {selectedApplicant ? (
      <>
              {console.log(selectedApplicant)}  {/* Check what the actual data is */}

        <div className="status-control text-center mb-4">
          <label htmlFor="status-select"><strong>Application Status:</strong></label>
          <select
            id="status-select"
            className="form-control w-50 mx-auto"
            value={selectedApplicant.application_status || ''}
            onChange={(e) => handleUpdateStatus(e.target.value, selectedApplicant.personal_info_id)}
          >
            <option value="Pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="Denied">Denied</option>
            <option value="Hired">Hired</option>
            {isAdmin && (
            <option value="Archived">Archived</option>
            )}

          </select>
        </div>
        <hr />
        <hr />
        <hr />

              <h4>Store applied to:  {selectedApplicant.personal_storeNumber}</h4>
              <hr />
              <hr />
              <hr />

              <h5>Personal Information</h5>
              <p><strong>Name:</strong> {selectedApplicant.personal_full_name || 'N/A'}</p>
              <p><strong>Date of Birth:</strong> {selectedApplicant.personal_dob || 'N/A'}</p>
              <p><strong>SSN:</strong> XXX-XX-{selectedApplicant.personal_ssn ? selectedApplicant.personal_ssn.slice(-4) : 'N/A'}</p>
              <p><strong>Address:</strong> {selectedApplicant.personal_address || 'N/A'}</p>
              <p><strong>City/State/ZIP:</strong> {`${selectedApplicant.personal_city || 'N/A'}, ${selectedApplicant.personal_state || 'N/A'} ${selectedApplicant.personal_zip || ''}`}</p>
              <p><strong>Phone:</strong> {selectedApplicant.personal_phone || 'N/A'}</p>
              <p><strong>Email:</strong> {selectedApplicant.personal_email || 'N/A'}</p>
              <p><strong>How did you hear about us?:</strong> {selectedApplicant.personal_hear_of_us || 'N/A'}</p>
              <p><strong>Relatives working here:</strong> {selectedApplicant.personal_relatives || 'N/A'}</p>

              <hr />
              <hr />

              <h5>Education Information</h5>
                {selectedApplicant.education && selectedApplicant.education.length > 0 ? (
                  selectedApplicant.education.map((education, index) => (
                    <div key={index}>
                      <p><strong>Education Level:</strong> {education.education_level || 'N/A'}</p>
                      <p><strong>School Name:</strong> {education.education_name || 'N/A'}</p>
                      <p><strong>City/State:</strong> {education.education_city_state || 'N/A'}</p>
                      <p><strong>Years Attended:</strong> {education.education_years_attended || 'N/A'}</p>
                      <p><strong>Graduated:</strong> {education.education_graduated ? 'Yes' : 'No'}</p>
                      {education.education_degree && (
                        <p><strong>Degree:</strong> {education.education_degree}</p>
                      )}
                      <hr />
                    </div>
                  ))
                ) : (
                  <p>No education records found.</p>
                )}

              <hr />

              <h5>Employment Information</h5>
              <p><strong>Position Desired:</strong> {selectedApplicant.employment_position || 'N/A'}</p>
              <p><strong>Salary Desired:</strong> {selectedApplicant.employment_salary || 'N/A'}</p>
              <p><strong>Start Date:</strong> {selectedApplicant.employment_start_date || 'N/A'}</p>
              <p><strong>Can Work Nights:</strong> {selectedApplicant.employment_nights ? 'Yes' : 'No'}</p>
              <p><strong>Can Work Weekends:</strong> {selectedApplicant.employment_weekends ? 'Yes' : 'No'}</p>
              <p><strong>Willing to Relocate:</strong> {selectedApplicant.employment_relocate ? 'Yes' : 'No'}</p>
              <p><strong>US Citizen:</strong> {selectedApplicant.employment_us_citizen ? 'Yes' : 'No'}</p>
              <p><strong>Ever Been Fired:</strong> {selectedApplicant.employment_fired_before ? 'Yes' : 'No'}</p>

              {(selectedApplicant.employment_fired_before) && (
                <p><strong>Reason for Firing:</strong> {selectedApplicant.employment_fired_reason || 'N/A'}</p>
              ) || ""}

              <p><strong>Convicted of a Misdemeanor:</strong> {selectedApplicant.employment_convicted_misd ? 'Yes' : 'No'}</p>
              <p><strong>Convicted of a Felony:</strong> {selectedApplicant.employment_convicted_felon ? 'Yes' : 'No'}</p>

              {(selectedApplicant.employment_convicted_felon || selectedApplicant.employment_convicted_misd) && (
                <p><strong>Reason for Conviction:</strong> {selectedApplicant.employment_convicted_reason || 'N/A'}</p>
              )}
              <hr />


              <h5>Previous Employment</h5>
              {selectedApplicant.previousEmployment && selectedApplicant.previousEmployment.length > 0 ? (
                selectedApplicant.previousEmployment.map((employment, index) => (
                  <div key={index}>
                    <p><strong>Employer Name:</strong> {employment.previous_employer_name || 'N/A'}</p>
                    <p><strong>Employer Address:</strong> {employment.previous_employer_address || 'N/A'}</p>
                    <p><strong>City/State:</strong> {employment.previous_employer_citystate || 'N/A'}</p>
                    <p><strong>Phone:</strong> {employment.previous_employer_phone || 'N/A'}</p>
                    <p><strong>Supervisor:</strong> {employment.previous_employer_supervisor || 'N/A'}</p>
                    <p><strong>Job Title:</strong> {employment.previous_employer_job_title || 'N/A'}</p>
                    <p><strong>Starting Salary:</strong> {employment.previous_employer_starting_salary || 'N/A'}</p>
                    <p><strong>Ending Salary:</strong> {employment.previous_employer_ending_salary || 'N/A'}</p>
                    <p><strong>Responsibilities:</strong> {employment.previous_employer_responsibilities || 'N/A'}</p>
                    <p><strong>Start Date:</strong> {employment.previous_employer_start_date || 'N/A'}</p>
                    <p><strong>End Date:</strong> {employment.previous_employer_end_date || 'N/A'}</p>
                    <p><strong>Reason for Leaving:</strong> {employment.previous_employer_reason_for_leaving || 'N/A'}</p>
                    <p><strong>Can We Contact:</strong> {employment.previous_employer_contact ? 'Yes' : 'No'}</p>
                    
                    {(!employment.previous_employer_contact) && (
              <p><strong>No Contact Reason:</strong> {employment.previous_employer_no_contact_reason || 'N/A'}</p>
            ) || ""}
                    <hr />
                  </div>
                ))
              ) : (
                <p>No previous employment records found.</p>
              )}

              <hr />

              <h5>References</h5>
              {selectedApplicant.references && selectedApplicant.references.length > 0 ? (
                selectedApplicant.references.map((reference, index) => (
                  <div key={index}>
                    <p><strong>Reference Name:</strong> {reference.reference_name || 'N/A'}</p>
                    <p><strong>Relationship:</strong> {reference.reference_relationship || 'N/A'}</p>
                    <p><strong>Company:</strong> {reference.reference_company || 'N/A'}</p>
                    <p><strong>Phone:</strong> {reference.reference_phone || 'N/A'}</p>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No references found.</p>
              )}

              <hr />

              <h5>Other Information</h5>
              {selectedApplicant.otherInfo && selectedApplicant.otherInfo.length > 0 ? (
                selectedApplicant.otherInfo.map((info, index) => (
                  <div key={index}>
                    <p><strong>Related Skills:</strong> {info.other_related_skills || 'N/A'}</p>
                    <p><strong>Activities:</strong> {info.other_activities || 'N/A'}</p>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No other information found.</p>
              )}
            </>
          ) : (
            <p>Loading applicant details...</p>
          )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
   </div>
  );
};


export default Mang;
