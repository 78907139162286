import './App.css';
import ApplicationForm from './ApplicationForm';
import Mang from './mang.js';  
import SubmittedPage from './SubmittedPage.js';  
import Login from './Login.js';  
import Users from './Users.js';  
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAppPage, setIsAppPage] = useState(false);
  const location = useLocation(); // Hook to get the current location
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate(); // Hook to programmatically navigate    
  const production = true;

  if (production) {
    console.error = () => {};
    console.warn = () => {};
  }

  useEffect(() => {
    setIsAppPage((location.pathname === '/') || (location.pathname === '/lgn') || (location.pathname === '/submitted'));
  
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsLoggedIn(true);
  
      // Check admin permissions
      axios
        .get('https://shosky-app.onrender.com/api/permissions', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data)
          setIsAdmin(response.data);
        })
        .catch((error) => {
          // If the token is invalid, remove it silently
          if (error.response && error.response.status === 403) {
            localStorage.removeItem('authToken');
            setIsLoggedIn(false); // Update login state
            setIsAdmin(false);    // Reset admin state
          }
        });
    }
  }, [location.pathname, isAppPage]);
  


  const handleLogout = () => {
    setIsLoggedIn(false);     
    localStorage.removeItem('authToken'); // Remove the token from local storage
  };

  const handleLogoDoubleClick = () => {
    navigate('/lgn'); // Navigate to the /lgn page
  };

  return (
    <div className="App">
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Shoney%27s_Logo_2019.svg/1200px-Shoney%27s_Logo_2019.svg.png"
        alt=""
        className="app-logo"
        onDoubleClick={handleLogoDoubleClick} 
        style={{
          position: "absolute",
          top: "10px",
          left: "0%",
          padding: "10px 20px",
          height: "50px", 
        }}
      />
      
      {/* Logout button is only visible when not on the Application Page */}
      {isLoggedIn && !isAppPage && (
  <div style={{ position: "absolute", top: "10px", right: "10px", display: "flex", gap: "10px" }}>
    <Link
      to="/lgn"
      onClick={handleLogout}
      style={{
        padding: "10px 20px",
        color: "#fff",
        backgroundColor: "#007bff",
        textDecoration: "none",
        borderRadius: "5px",
        textAlign: "center",
      }}
    >
      Logout
    </Link>

    <div style={{ position: "absolute", right: "100px", display: "flex", gap: "10px" }}>
      <Link
        to="/mang"
        style={{
          padding: "10px 20px",
          color: "#fff",
          backgroundColor: "#28a745",
          textDecoration: "none",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        Apps
      </Link>

      {/* Third button visible only for admins */}
      {isAdmin && (
        <Link
          to="/users"
          style={{
            padding: "10px 20px",
            color: "#fff",
            backgroundColor: "#dc3545",
            textDecoration: "none",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          Admin
        </Link>
      )}
    </div>
  </div>
)}



      <Routes>
        {/* Route for the application form */}
        <Route path="/" element={<ApplicationForm />} />
        
        {/* Route for the Mang query page */}
        <Route path="/mang" element={<Mang />} />

        {/* Route for the Login page */}
        <Route path="/lgn" element={<Login />} />

        {/* Route for the Users page */}
        <Route path="/users" element={<Users />} />

        {/* Route for the Submitted page */}
        <Route path="/submitted" element={<SubmittedPage />} />
      </Routes>
    </div>
  );
}

const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;
